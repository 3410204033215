<template>
    <div
        v-show="visible"
        class="transform transition-all duration-200 bg-white rounded-xl shadow-sm border border-gray-200"
    >
        <!-- Main Content -->
        <div class="p-4">
            <!-- Header -->
            <div class="flex items-center justify-between mb-4">
                <div class="flex items-center gap-2">
                    <i class="fas fa-sliders-h text-gray-500"></i>
                    <h3 class="font-medium text-gray-700">Filtre</h3>
                </div>
                <div class="flex items-center gap-2">
                    <button
                        @click="resetFilters"
                        class="text-sm text-gray-500 hover:text-red-600 transition flex items-center gap-1"
                    >
                        <i class="fas fa-undo-alt w-4 h-4"></i>
                        <span class="hidden sm:inline">Resetează</span>
                    </button>
                    <button
                        @click="$emit('toggle-filters')"
                        class="text-gray-500 hover:text-gray-700 lg:hidden"
                    >
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>

            <!-- Quick Filters Grid -->
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mb-3">
                <!-- Source Filter -->
                <div class="relative">
                    <select
                        v-model="filters.selectedSource"
                        class="w-full pl-3 pr-8 py-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-main focus:border-transparent"
                    >
                        <option value="">🏢 Toate sursele</option>
                        <option v-for="(source, key) in sources" :key="key" :value="key">
                            {{ source }}
                        </option>
                    </select>
                </div>

                <!-- Political Orientation Filter -->
                <div class="relative">
                    <select
                        v-model="filters.politicalOrientation"
                        class="w-full pl-3 pr-8 py-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-main focus:border-transparent"
                    >
                        <option value="">🎯 Orientare politică</option>
                        <option value="right">→ Dreapta</option>
                        <option value="center">○ Centru</option>
                        <option value="left">← Stânga</option>
                    </select>
                </div>

                <!-- Party Filter -->
                <div class="relative">
                    <select
                        v-model="filters.selectedParty"
                        class="w-full pl-3 pr-8 py-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-main focus:border-transparent"
                    >
                        <option value="">🏛️ Toate partidele</option>
                        <option v-for="(partyName, key) in parties" :key="key" :value="key">
                            {{ partyName }}
                        </option>
                    </select>
                </div>
            </div>

            <!-- Advanced Filters Toggle -->
            <button
                @click="showAdvanced = !showAdvanced"
                class="flex items-center gap-1 text-sm text-gray-500 hover:text-gray-700 mb-3"
            >
                <i :class="['fas', showAdvanced ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                <span>Filtre avansate</span>
            </button>

            <!-- Advanced Filters -->
            <div v-if="showAdvanced" class="space-y-3">
                <!-- Subjectivity Filter -->
                <div class="flex flex-wrap gap-2">
                    <select
                        v-model="filters.subjectivityComparison"
                        class="w-32 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-main"
                    >
                        <option value="">Subjectivitate</option>
                        <option value="less_than">Mai mic</option>
                        <option value="greater_than">Mai mare</option>
                    </select>
                    <input
                        type="number"
                        v-model="filters.subjectivityThreshold"
                        min="0"
                        max="100"
                        class="flex-1 min-w-[100px] text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-main"
                        placeholder="Valoare %"
                    />
                </div>

                <!-- Objectivity Filter -->
                <div class="flex flex-wrap gap-2">
                    <select
                        v-model="filters.objectivityComparison"
                        class="w-32 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-main"
                    >
                        <option value="">Obiectivitate</option>
                        <option value="less_than">Mai mic</option>
                        <option value="greater_than">Mai mare</option>
                    </select>
                    <input
                        type="number"
                        v-model="filters.objectivityThreshold"
                        min="0"
                        max="100"
                        class="flex-1 min-w-[100px] text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-main"
                        placeholder="Valoare %"
                    />
                </div>
            </div>

            <!-- Apply Button -->
            <button
                @click="applyFilters"
                class="mt-4 w-full bg-main text-white rounded-lg py-2 text-sm font-medium hover:bg-main/90 transition-colors duration-200 flex items-center justify-center gap-2"
            >
                <i class="fas fa-search"></i>
                Aplică filtrele
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        sources: {
            type: Object,
            default: () => ({})
        },
        parties: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            showAdvanced: false,
            filters: {
                selectedSource: "",
                politicalOrientation: "",
                selectedParty: "",
                subjectivityThreshold: null,
                subjectivityComparison: "",
                objectivityThreshold: null,
                objectivityComparison: ""
            }
        }
    },

    methods: {
        resetFilters() {
            this.filters = {
                selectedSource: "",
                politicalOrientation: "",
                selectedParty: "",
                subjectivityThreshold: null,
                subjectivityComparison: "",
                objectivityThreshold: null,
                objectivityComparison: ""
            }
            this.updateURLParams()
            this.$emit("reset-filters")
        },

        applyFilters() {
            this.updateURLParams()
            this.$emit("apply-filters", this.filters)
        },

        updateURLParams() {
            const params = new URLSearchParams(window.location.search)

            Object.keys(this.filters).forEach(key => {
                if (this.filters[key]) {
                    params.set(key, this.filters[key])
                } else {
                    params.delete(key)
                }
            })

            window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`)
        },

        loadFiltersFromURL() {
            const params = new URLSearchParams(window.location.search)

            Object.keys(this.filters).forEach(key => {
                if (params.has(key)) {
                    this.filters[key] = params.get(key)
                }
            })
        }
    },

    created() {
        this.loadFiltersFromURL()
    }
}
</script>
